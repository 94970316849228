import React from 'react'
import "../styles/footer2Styles.scss";


const footer2 = () => {
    return (
        <>
        <div className="footer2-container">
            <h2>©{new Date().getFullYear()} Bytelex Advocates</h2>
            <div className="social-LINK">
                <a href="https://www.facebook.com/bytelexhq/" target="_blank" rel="noopener norefferer">
                    <span className="LINK">FB.</span>
                </a>
                <a href="https://twitter.com/bytelex?lang=en" target="_blank" rel="noopener norefferer">
                    <span className="LINK">TW.</span>
                </a>
                <a href="https://www.linkedin.com/company/bytelex-advocates/" target="_blank" rel="noopener norefferer">
                    <span className="LINK">LI.</span>
                </a>
            </div>
        </div>
        </>
    )
}

export default footer2;