import React from "react"
import Layout from "../components/layout"
import { Link } from "gatsby"
import { Helmet } from 'react-helmet';
import Footer2 from '../components/footer2';


import '../styles/eventStyles.scss'
import { motion } from "framer-motion";

const EventPage = props => {
  return <Layout>
             <Helmet>
                <title>Next.Legal | Events</title>
                <meta name="description" content="Next.legal is a showcase of innovations in legal tech and finance for the African ecosystem." />
                <meta name="keywords" content="legal tech, startup, law" />
                <meta
                  name="robots"
                  content="max-snippet:-1, max-image-preview:large, max-video-preview:-1"
                />
                <link rel="canonical" href="https://nextlegal.io" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Next.Legal | Events" />
                <meta property="og:description" content="Next.legal is a showcase of innovations in legal tech and finance for the African ecosystem." />
                <meta property="og:url" content="https://nextlegal.io" />
                <meta property="og:site_name" content="Next.Legal" />
                <meta property="og:image" content="../assets/img/icon-256x256.png" />
                <meta property="og:image:secure_url" content="https://nextlegal.io" />
                <meta property="og:image:width" content="1280" />
                <meta property="og:image:height" content="720" />
                <meta name="twitter:card" content="../assets/img/icon-256x256.png" />
                <meta name="twitter:description" content="Next.legal is a showcase of innovations in legal tech and finance for the African ecosystem." />
                <meta name="twitter:title" content="Next.Legal | Events" />
                <meta name="twitter:image" content="../assets/img/icon-256x256.png" />
             </Helmet>
             <motion.div
                        animate={{y:0 , opacity: 1}}
                        initial={{ y: 72, opacity: 0 }}
                        transition={{
                          duration: 0.5,
                          ease: [0.6, 0.05, -0.01, 0.9],
                          /* staggerChildren: 0.1 */
                        }}
             >
             <div className="events-page">
                 <div className="event-content-2019">
                   <h2 className="event-content-title">Next.Legal 2019</h2>
                   <p className="event-content-para">On the 5th of July, 2019 Next.Legal brought together startup founders, investors, lawyers and enthusiasts to discuss changing trends in the startup ecospace as well as assess the startup and emerging companies legal space. The programme included a panel sponsored by the Berkeley Law Society titled 'Startups and Investor Discourse: Five years in an emerging ecosystem. How do you finance a tech-startup and exit in East Africa? '</p>
                 </div>

                 <div className="event-speakers">
                      <h2 className="speakers-title">Speakers</h2>
                   <div className="speaker-box">
                      <div className="event-speaker">
                            <img className="event-speaker-img" src="https://res.cloudinary.com/mfanolab-design/image/upload/v1596197112/next-legal/next-legal-speakers-joshua_r8wsxy.jpg" alt="speaker-image"/>
                            <h3 className="event-speaker-name">Joshua Businge Muleesi</h3>
                            <h3 className="event-speaker-company">Founder, Matibabu</h3>
                      </div>
                      <div className="event-speaker">
                            <img className="event-speaker-img" src="https://res.cloudinary.com/mfanolab-design/image/upload/v1596197112/next-legal/next-legal-speakers-chris_muwofy.jpg" alt="speaker-image"/>
                            <h3 className="event-speaker-name">Chrispinus Onyancha</h3>
                            <h3 className="event-speaker-company">Founder, ClinicPesa</h3>
                      </div>
                      <div className="event-speaker">
                            <img className="event-speaker-img" src="https://res.cloudinary.com/mfanolab-design/image/upload/v1596197111/next-legal/next-legal-speakers-angela_graq1w.jpg" alt="speaker-image"/>
                            <h3 className="event-speaker-name">Angela Kerubo</h3>
                            <h3 className="event-speaker-company">Project Leader, Open Capital Advisors</h3>
                      </div>
                      <div className="event-speaker">
                            <img className="event-speaker-img" src="https://res.cloudinary.com/mfanolab-design/image/upload/v1596197111/next-legal/next-legal-speakers-ivan_i2shdd.jpg" alt="speaker-image"/>
                            <h3 className="event-speaker-name">Ivan Mworozi</h3>
                            <h3 className="event-speaker-company">Founder, Printboda</h3>
                      </div>
                   </div>

                      <div className="event-video">
                          <h2 className="video-title">Watch the Highlights</h2>
                          <div className="video-box">
                              <div className="event-video">
                                  <iframe className="event-video-iframe" loading="lazy" width="100%" height="100%" src="https://www.youtube.com/embed/R987yqN52M8" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                              </div>
                          </div>
                    </div>
                 </div>
                 <Footer2/>
             </div>
            </motion.div>   
        </Layout>
}

export default EventPage
